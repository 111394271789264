// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_auth.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_auth.tsx");
  import.meta.hot.lastModified = "1710076161239.5305";
}
// REMIX HMR END

import { redirect } from "@remix-run/node";
import { Outlet } from "@remix-run/react";
import { authProvider } from "~/providers/authProvider.server";
export default function AuthLayout() {
  // no layout is applied for the auth routes
  return <Outlet />;
}

/**
 * If the current session is authenticated, we're redirecting the user to the home page.
 * Alternatively, we could also use the `Authenticated` component inside the `AuthLayout` to handle the redirect.
 * But, server-side redirects are more performant.
 */
_c = AuthLayout;
export const loader = async ({
  request
}) => {
  const {
    authenticated,
    redirectTo
  } = await authProvider.check(request);
  if (!authenticated && redirectTo) {
    return redirect(redirectTo);
  }
  return {};
};
var _c;
$RefreshReg$(_c, "AuthLayout");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;